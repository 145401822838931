<template>
	<div
		v-if="options.length > 0"
		class="row no-gutters">
		<div class="col">
			<select
				v-if="['xs','sm','md'].includes(windowWidth)"
				class="form-control rounded-0 p-1"
				:disabled="options.length <= 1"
				:value="selectedOption"
				@change="selectedOption = $event.target.value">
				<option
					v-for="(option, index) in options"
					:key="index"
					:value="option.value">
					{{ option.text }}
				</option>
			</select>
		</div>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'OptionSelectDropdown',
	mixins: [WindowSizes],
	props: {
		options: {
			type: Array,
			default: () => [],
		},
		selectFirst: {
			type: Boolean,
			default: true,
		},
		variant: {
			type: String,
			default: 'text',
			validator(value) {
				return ['text', 'color', 'size'].includes(value);
			},
		},
		// The option to be selected, if exists in options values
		initialSelectedOption: {
			type: [String, undefined],
			default: undefined,
		},
	},
	data() {
		return {
			selectedOption: null,
		};
	},
	watch: {
		selectedOption() {
			this.$emit('update:selectedOption', this.selectedOption);
		},
		options(newOpts) {
			if (newOpts.findIndex((opt) => opt.value === this.selectedOption) === -1) {
				this.selectFirstItem();
			}
		},
		initialSelectedOption() {
			this.selectedOption = this.initialSelectedOption;
		},
	},
	mounted() {
		this.selectDefaultItem();
	},
	methods: {
		// inVariant(variants) {
		// 	return variants.includes(this.variant);
		// },
		selectDefaultItem() {
			if (typeof this.initialSelectedOption !== 'undefined') {
				const toSelect = this.options.find((option) => option.value === this.initialSelectedOption);
				if (toSelect) {
					this.selectedOption = toSelect.value;
				} else {
					this.selectFirstItem();
				}
			} else {
				this.selectFirstItem();
			}
		},
		selectFirstItem() {
			if (this.selectFirst && this.options.length > 0) {
				this.selectedOption = this.options[0].value;
			}
		},
		// mapColor(color) {
		// 	switch (color) {
		// 	case 'white': return 'gray';
		// 	default: return color;
		// 	}
		// },
	},
};
</script>
<style scoped>
.rounded-btn {
	color: #73818f;
}
.rounded-btn:hover {
	color: #ffffff;
}
.rounded-btn-active {
	color: #ffffff;
}
.color-rounded-btn {
	width: 35px;
	height: 35px;
}
.color-active {
	padding: 4px !important;
	background-clip: content-box;
}
</style>
